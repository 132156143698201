import React, { useState, useEffect } from 'react'
import ToggleMenuHook from '../ToggleMenu';
import MenuOptions from './MenuOptions';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
export default function ContactUs() {
 const toggleMenu = ToggleMenuHook();
 const [captcha, setCaptcha] = useState(null);
 const [state, setState] = useState(
  {
   name: '', email: '', mobile_no: '', subject: '', description: '', gcaptcha: '',
   errMsgName: '', errMsgEmail: '', errMsgMobile: '', errMsgSubject: '', errMsgGcaptcha: '', succMsg: ""
  });
 const resetCaptcha = () => {
  captcha.reset();
 }
 //input set in state
 const onChangeFun = (e) => {
  setState({ ...state, [e.target.name]: e.target.value })
 }
 const onSubmit = (e) => {
  setState({ ...state, errMsgName: "", errMsgEmail: "", errMsgMobile: "", errMsgSubject: "", errMsg: "", errMsgGcaptcha: "", succMsg: "" });
  e.preventDefault();
  // get our form data out of state
  const { name, email, mobile_no, subject, description, gcaptcha } = state;
  axios.post("http://localhost/react/jemeconnecte/admin/api/FormSubmit", { name, email, subject, description, gcaptcha })
   .then((response) => {
    //access the results here....
    if (response.data.status === 1) {
     resetCaptcha();
     setState({
      name: '', email: '', mobile_no: '', subject: '', description: '', gcaptcha: false,
      errMsgName: '', errMsgEmail: '', errMsgMobile: '', errMsgSubject: '', errMsg: "", errMsgGcaptcha: '', succMsg: response.data.msg,
     })
    }

    if (response.data.status === 0) {
     setState({
      ...state,
      errMsgName: (response.data.errors.name != undefined) ? response.data.errors.name[0] : '',
      errMsgEmail: (response.data.errors.email != undefined) ? response.data.errors.email[0] : '',
      errMsgMobile: (response.data.errors.mobile_no != undefined) ? response.data.errors.mobile_no[0] : '',
      errMsgSubject: (response.data.errors.subject != undefined) ? response.data.errors.subject[0] : '',
      errMsgGcaptcha: (response.data.errors.gcaptcha != undefined) ? response.data.errors.gcaptcha[0] : '',
      errMsg: response.data.msg,
     });
     setTimeout(() => {
      setState({
       gcaptcha: false,
       errMsgName: '', errMsgEmail: '', errMsgMobile: '', errMsgSubject: '', errMsg: "", errMsgGcaptcha: '', succMsg: ""
      });
     }, 20000);
    }
   })
   .catch((error) => {
    console.log(error);
   });
 }
 return (
  <React.Fragment>
   <div className="header inner-header inner-banner-contact">
    <div className="header">
     <div className="container">
      <div className="header-inner-box">
       <div className="toggle-button-box">
        <button className="toggle-button" onClick={toggleMenu.toggleClass} type="button">
         <img src="images/toggle-icon.png" alt="" />
        </button>
       </div>

       <div className="trial-botton">
        <a target="_blank" href={"https://kr.cisinlive.com/motorrepairbill-services/register"}>Start Trial Today</a>
        <br />
        <a target="_blank" href={"https://kr.cisinlive.com/motorrepairbill-services/login"}>Login</a>
       </div>
       <div className={!toggleMenu.isMenuOpen ? 'menu' : 'menu open'}>
        <button type="button" onClick={toggleMenu.toggleCancel} className="close-menu">X</button>
        <MenuOptions />
       </div>

      </div>
     </div>
    </div>

    
    <div className="home-banner">

     {/* <a href={"/"} className="logo"><img src="images/logo.png" alt="" /></a> */}
     <div>
      <h1>Contact us</h1>
      <p>Home / Contact us</p>
     </div>

    </div>
   </div>
   <div className="content">
    <div className="contact-box">
     <div className="container">
      <div className="contact-inner-box">
       <div className="contact-left">
        <h2 onClick={() => resetCaptcha()}>Get Information</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum congue est massa, in porttitor lacus luctus at. Phasellus et vehicula elit, vel lobortis metus. Etiam mauris massa, euismod id blandit ut,</p>
        <p>You may find your answer in our <span>Frequently Asked Questions</span>, or on our instructional <span>YouTube</span> channel.</p>

       </div>
       <form id="contact-form" onSubmit={onSubmit} method="POST">
        <div className="contact-right">
         {state.errMsg ? <div class="alert alert-danger" role="alert">{state.errMsg}</div> : ''}
         {state.succMsg ? <div class="alert alert-success" role="alert">{state.succMsg}</div> : ''}
         <div className="field-row">
          <label>Enter your name<span className="mand">*</span></label>
          <input type="text" value={state.name} name="name" onChange={(e) => { setState({ ...state, errMsgName: '' }); onChangeFun(e); }} placeholder="Enter your Name" />
          <span id="errMsgName" className="text-danger">{state.errMsgName}</span>
         </div>
         <div className="field-row">
          <label>Enter your E-mail<span className="mand">*</span></label>
          <input type="email" value={state.email} name="email" onChange={(e) => { setState({ ...state, errMsgEmail: '' }); onChangeFun(e); }} placeholder="Enter your E-mail" />
          <span id="errMsgEmail" className="text-danger">{state.errMsgEmail}</span>
         </div>
         <div className="field-row">
          <label>Enter your Mobile Number<span className="mand">*</span></label>
          <input type="text" value={state.mobile_no} name="mobile_no" maxLength="10" minLength="10" onChange={(e) => isNaN(e.target.value) ? e.target.value = "" : onChangeFun(e)} placeholder="Enter your Mobile Number" />
          <span id="errMsgMobile" className="text-danger">{state.errMsgMobile}</span>
         </div>
         <div className="field-row">
          <label>Enter your subject<span className="mand">*</span></label>
          <input type="subject" value={state.subject} name="subject" onChange={(e) => { setState({ ...state, errMsgSubject: '' }); onChangeFun(e); }} placeholder="Enter your subject" />
          <span id="errMsgSubject" className="text-danger">{state.errMsgSubject}</span>
         </div>
         <div className="field-row">
          <label>Your message</label>
          <textarea name="description"
           onChange={(e) => onChangeFun(e)}
           value={state.description} />
         </div>
         <div className="captcha-row">
          <input type="hidden" value={state.gcaptcha} name="gcaptcha" />
          <ReCAPTCHA ref={e => setCaptcha(e)} onChange={() => setState({ ...state, gcaptcha: true })} onExpired={() => setState({ ...state, gcaptcha: false })} onErrored={() => setState({ ...state, gcaptcha: false })} sitekey="6LdcO84cAAAAANh8vK2jLXflDmUCG-14zAc6Y0ex"
          />
          <span className="text-danger">{state.errMsgGcaptcha}</span>
         </div>
         <div className="field-button-row">
          <input type="submit" value="Submit" className="field-button" />
         </div>
        </div>
       </form>
      </div>
     </div>
    </div>
    <div className="map-box">
     <iframe width="600" height="450" style={{ "border": 0 }} loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=207%20%2C%20Regent%20street%203rd%20floor%20office%2C%20London%20W1B3HH%2C%20Great%20Britain&key=AIzaSyA0gkRqTn7RUvLpD7VwfHhiaa3PdrJZ29A"></iframe>
    </div>
   </div>
  </React.Fragment >
 )
}
