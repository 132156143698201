import React, { useEffect } from 'react'
import ToggleMenuHook from '../ToggleMenu'; 
import MenuOptions from './MenuOptions';
import { tns } from 'tiny-slider/src/tiny-slider';

export default function PrivacyPolicies() {
    const toggleMenu = ToggleMenuHook();
    useEffect(() => {
        const slider = tns({
            container: '.feature-slider',
            loop: true,
            margin: 0,
            slideBy: 'page',
            nav: false,
            items: 1,
            dots: true,
            nav: true,
            navPosition: "bottom",
            navAsThumbnails: true,
            controls: false,
            mouseDrag: true,
        });
    })
    return (
        <>
            <div className="header inner-header inner-banner-about">
                <div className="header">
                    <div className="container">
                        <div className="header-inner-box">
                            <div className="toggle-button-box">
                                <button className="toggle-button" onClick={toggleMenu.toggleClass} type="button">
                                    <img src="images/toggle-icon.png" alt="" />
                                </button>
                            </div>

                            <div className="trial-botton">
                                <a target="_blank" href={"https://kr.cisinlive.com/motorrepairbill-services/register"}>Start Trial Today</a>
                                <br/>
                                <a target="_blank" href={"https://kr.cisinlive.com/motorrepairbill-services/login"}>Login</a>
                            </div>
                            <div className={!toggleMenu.isMenuOpen ? 'menu' : 'menu open'}>
                                <button type="button" onClick={toggleMenu.toggleCancel} className="close-menu">X</button>
                                <MenuOptions />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="home-banner">

                    <a href={"/"} className="logo"><img src="images/logo.png" alt="" /></a>
                    <div>
                        <h1>Privacy Policies</h1>
                        <p>Home / Privacy Policies</p>
                    </div>

                </div>
            </div>
            <div className="content">
                <div className="shop-box">
                    <div className="container">

                        <div className="home-about-title">
                            <h2><span>RUNNING SHOP</span> About our easy auto repair software for mechanics</h2>
                            <p>Designed to be user friendly with reliablity in mind, targetted at small and independant car repair shops and mobile mechanics. How you manage your auto repair shop requires the right software to run your business more efficiently, keep accurate track of all your work documents and finances, make your business easier to run and more profitable.</p>
                            <p>Create an account today and be up and running in less than 5 minutes. You can choose from subscription plans starting from $7.99/month, or alternatively you can Pay As You Go with no monthly obligation from $9.99 for 10 invoices.</p>
                            <p>Our price plans are fully inclusive with an active account, no extra add-on charges to unlock premium features, like some other software, also your data will always be available and your account accessable even after cancellation or expiry.</p>
                            <p>You can evaluate our software for free with a functional live demo.</p>
                        </div>

                    </div>
                </div>
                <div className="about-feature-box">
                    <div className="container">
                        <div className="home-about-title">
                            <h2><span>FEATURES</span> Why Motor Repair Bill is Much Popular</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br /> Lorem Ipsum has been the industry's standard dummy</p>
                        </div>
                        <div className="feature-slider">
                            <div className="item">
                                <div className="popular-col-box">
                                    <div className="popular-col">
                                        <img src="images/about-feature-icon1.png" alt="" />
                                        <h3>Auto repair invoice</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon2.png" alt="" />
                                        <h3>Quote & bookings</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon3.png" alt="" />
                                        <h3>Detailed client</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon4.png" alt="" />
                                        <h3>Vehicle billing dashboard</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon5.png" alt="" />
                                        <h3>VIN/Plate Decoder</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon6.png" alt="" />
                                        <h3>Vehicle lookup</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon7.png" alt="" />
                                        <h3>CARFAX integration</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon8.png" alt="" />
                                        <h3>Uploading invoices</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon9.png" alt="" />
                                        <h3>Repair order</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="popular-col-box">

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon1.png" alt="" />
                                        <h3>Auto repair invoice</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon2.png" alt="" />
                                        <h3>Quote & bookings</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon3.png" alt="" />
                                        <h3>Detailed client</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon4.png" alt="" />
                                        <h3>Vehicle billing dashboard</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon5.png" alt="" />
                                        <h3>VIN/Plate Decoder</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon6.png" alt="" />
                                        <h3>Vehicle lookup</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon7.png" alt="" />
                                        <h3>CARFAX integration</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon8.png" alt="" />
                                        <h3>Uploading invoices</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon9.png" alt="" />
                                        <h3>Repair order</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                </div>
                            </div>

                            <div className="item">
                                <div className="popular-col-box">

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon1.png" alt="" />
                                        <h3>Auto repair invoice</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon2.png" alt="" />
                                        <h3>Quote & bookings</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon3.png" alt="" />
                                        <h3>Detailed client</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon4.png" alt="" />
                                        <h3>Vehicle billing dashboard</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon5.png" alt="" />
                                        <h3>VIN/Plate Decoder</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon6.png" alt="" />
                                        <h3>Vehicle lookup</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon7.png" alt="" />
                                        <h3>CARFAX integration</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon8.png" alt="" />
                                        <h3>Uploading invoices</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>

                                    <div className="popular-col">
                                        <img src="images/about-feature-icon9.png" alt="" />
                                        <h3>Repair order</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="demonstration-box">
                    <div className="container">
                        <div className="home-about-title">
                            <h2><span>DEMONSTRATION</span> Easy Motor Repair Invoice software</h2>
                            <p>Let our auto repair shop software look after your day to day business while you concentrate on your work. Auto Repair Bill will make running your shop a lot easier, easy data entry with auto-complete on parts and labor items, connect your Google contacts to easily add clients.</p>
                        </div>
                        <div className="demonstration-inner-box">
                            <div className="demonstration-left">
                                <img src="images/iphone.png" alt="" />
                            </div>
                            <div className="demonstration-right">
                                <div className="demonstration-list">
                                    <span><img src="images/demonstration-icon1.png" alt="" /></span>
                                    <h3>Professional Invoices</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                                </div>
                                <div className="demonstration-list">
                                    <span><img src="images/demonstration-icon2.png" alt="" /></span>
                                    <h3>Repair Orders</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                                </div>
                                <div className="demonstration-list">
                                    <span><img src="images/demonstration-icon3.png" alt="" /></span>
                                    <h3>Bookings</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                                </div>
                                <div className="demonstration-list">
                                    <span><img src="images/demonstration-icon4.png" alt="" /></span>
                                    <h3>Estimates</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subscription-box">
                    <div className="container">

                        <div className="subscription-inner-box">

                            <div className="subscription-left">
                                <h3>Ready to Get Started?</h3>
                                <p>See all subscriptions and offers and Sign Up.</p>
                            </div>

                            <div className="subscription-right">
                                <a href="true" className="demo-button">Live Demo</a>
                                <a href="true" className="sign-button">Sign up</a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
