import React from 'react'
import ToggleMenuHook from '../ToggleMenu';
import MenuOptions from './MenuOptions';
import Collapsible from 'react-collapsible'; 
export default function Faq() {
    const toggleMenu = ToggleMenuHook();
    return (
        <div>
            <div className="header inner-header inner-banner-faq">
                <div className="header">
                    <div className="container">
                        <div className="header-inner-box">
                            <div className="toggle-button-box">
                                <button className="toggle-button" onClick={toggleMenu.toggleClass} type="button">
                                    <img src="images/toggle-icon.png" alt="" />
                                </button>
                            </div>

                            <div className="trial-botton">
                                <a target="_blank" href={"https://kr.cisinlive.com/motorrepairbill-services/register"}>Start Trial Today</a>
                                <br/>
                                <a target="_blank" href={"https://kr.cisinlive.com/motorrepairbill-services/login"}>Login</a>
                            </div>
                            <div className={!toggleMenu.isMenuOpen ? 'menu' : 'menu open'}>
                                <button type="button" onClick={toggleMenu.toggleCancel} className="close-menu">X</button>
                                <MenuOptions />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="home-banner">

                    <a href={"/"} className="logo"><img src="images/logo.png" alt="" /></a>
                    <div>
                        <h1>Frequently Asked Auestion</h1>
                        <p>Home / FAQ</p>
                    </div>

                </div>
            </div>
            <div className="content">
                <div className="faq-box">
                    <div className="container">
                        <Collapsible className="panel-group" open="true" trigger="Can I try the software for free before buying?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="Is this a desktop app, can it run offline?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="I can't print or am getting blank PDF pages">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="Can I use more than one tax rate?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="Can I set tax on parts only and not for labor?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="Can I create a company with multiple vehicles?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="My country, state or business requires additional legal/regulatory information, how can I add this...">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="Does your software have inventory management and parts ordering?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="Can more than one user be logged in?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="Can I customise the invoice format?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="Is my data stored if I cancel my account?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                        <Collapsible className="panel-group" trigger="What support and training options are available?">
                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </Collapsible>
                    </div>
                </div>
            </div>
        </div>
    )
}
