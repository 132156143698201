import React from 'react';
import { Link, useParams } from 'react-router-dom';
export default function () {
 const { sent_notifications_id, employee_id } = useParams();
    return (
        <ul>
            <li><Link to={'/'}>Domicile</Link>  </li>
            <li className="active"><Link to="/about">À propos de nous</Link>  </li>
            <li><Link to="/termscondition">Termes et conditions</Link>  </li>
            {/* <li><Link to="faq">Faq</Link>  </li>
            <li><Link to="/contact">Contact Us</Link>  </li>
            <li className="trial-botton"><a target="_blank" href="https://kr.cisinlive.com/motorrepairbill-services/demo-login">Demo</a></li>
            <li className="trial-botton"><a target="_blank" href="https://kr.cisinlive.com/motorrepairbill-services/register">Start Trial Today</a></li>
            <li className="trial-botton"><a target="_blank" href="https://kr.cisinlive.com/motorrepairbill-services/login">Login</a></li> */}
        </ul>
    )
}