import React, { useState, useEffect } from 'react'
import Navigation from './Navigation.js';
import { APIURL } from '../constants/global';
import axios from "axios"; 
import { useParams } from 'react-router-dom';
 
export default function Formsubmission() {

 const [mystate, setMystate] = useState(
  {
   errMes: '', 
   home_content: '', errMsghome_content: ''
   //, gcaptcha: '', errMsgGcaptcha: ''
  });


  const [stateOpen, setOpenState] = useState(
   {
    lang: '', succMsg: "", errMsg: "", resp: "", errMsgFormOpened:'',
    //, gcaptcha: '', errMsgGcaptcha: ''
   });
 useEffect(() => {

  let config = {
   headers: {
     'API-ACCESS-TOKEN': '4942de482cf098fb1e0d5b6ba6dd2f06',
   }
  }
  


  axios.post(APIURL+"admin/api/getPageContent", {page_name:'home_content'}, config)  
  .then((response) => {
   //access the results here....
   
   if (response.data.status === 1) {
    // alert(JSON.stringify(response.data.responseContent.content));
    setMystate({    
     errMsg: "", home_content: response.data.responseContent.content, errMsghome_content:'',
     // succMsg: response.data.msg, 
    })
   }
 
   if (response.data.status === 0) {
    
    setMystate({
     ...mystate,
     errMsg: response.data.msg, errMsghome_content: response.data.msg, home_content:''
    });
    setTimeout(() => {
     setMystate({errMsg: "", succMsg: "", resp: "", errMsghome_content: ""
     });
    }, 20000);
   }
  })
 
  .catch((error) => {
   console.log(error);
  });

  let lang='fr';
  axios.post(APIURL+"admin/api/FormOpened", { sent_notifications_id, employee_id, lang }, config)  
  .then((response) => {
   //access the results here....
   
   if (response.data.status === 1) {
    setOpenState({
     errMsg: "", succMsg: response.data.msg, resp: JSON.stringify(response.data), errMsgFormOpened:'',
    })
   }
 
   if (response.data.status === 0) {
    setOpenState({
     ...stateOpen,
     errMsg: response.data.msg, errMsgFormOpened: JSON.stringify(response.data)
    });
    setTimeout(() => {
     setOpenState({errMsg: "", succMsg: "", resp: "", errMsgFormOpened: ""
     });
    }, 20000);
   }
  })
 
  .catch((error) => {
   console.log(error);
  });
 }, []);
 // const toggleMenu = ToggleMenuHook();
 // const [captcha, setCaptcha] = useState(null);
 const [state, setState] = useState(
  {
   firstName: '', 
   lastName: '', email: '', lang: '',
   errMsgFirstName: '',  errMsgLastName: '', errMsgEmail: '', succMsg: ""
   //, gcaptcha: '', errMsgGcaptcha: ''
  });
 // const resetCaptcha = () => {
 //  captcha.reset();
 // }
 //input set in state
 const onChangeFun = (e) => {
  setState({ ...state, [e.target.name]: e.target.value })
 }
 const onSubmit = (e) => {
  setState({ ...state, errMsgFirstName: "", errMsgLastName: "", errMsgEmail: "", errMsg: "", succMsg: "", errMsgLang: '' });
  // , errMsgGcaptcha: ""
  e.preventDefault();
  // get our form data out of state
  const { firstName, lastName, email, lang } = state;

  let config = {
   headers: {
     'API-ACCESS-TOKEN': '4942de482cf098fb1e0d5b6ba6dd2f06',
   }
  }
// alert('firstName- '+firstName+' lastName- '+ lastName+' email- '+ email+' sent_notifications_id- '+ sent_notifications_id+' employee_id- '+ employee_id+' lang- ' + lang);
  axios.post(APIURL+"admin/api/FormSubmit", { firstName, lastName, email, sent_notifications_id, employee_id, lang }, config)
   .then((response) => {
    //access the results here....
    if (response.data.status === 1) {
     // resetCaptcha();
     setState({
      firstName: '', lastName: '', email: '', lang: '',
      errMsgFirstName: '', errMsgLastName: '', errMsgEmail: '', errMsg: "", errMsgLang: '', succMsg: response.data.msg
      // , errMsgGcaptcha: ''
     })
    }

    if (response.data.status === 0) {
     alert(JSON.stringify(response.data));
     setState({
      ...state,
      errMsgFirstName: (response.data.errors.firstName !== undefined) ? response.data.errors.firstName[0] : '',
      errMsgLastName: (response.data.errors.lastName !== undefined) ? response.data.errors.lastName[0] : '',
      errMsgEmail: (response.data.errors.email !== undefined) ? response.data.errors.email[0] : '',
      // errMsgGcaptcha: (response.data.errors.gcaptcha !== undefined) ? response.data.errors.gcaptcha[0] : '',
      errMsgLang: (response.data.errors.lang !== undefined) ? response.data.errors.lang[0] : '', 
      errMsg: response.data.msg,
      // responseData: response.data,
     });
     // setTimeout(() => {
     //  setState({
     //   errMsgLang: '', errMsgFirstName: '', errMsgLastName: '', errMsgEmail: '', errMsg: "", succMsg: "",
     //   // responseData: "",
     //   // errMsgGcaptcha: '', gcaptcha: false, 
     //  });
     // }, 20000);
    }
   });
   // .catch((error) => {
   //  console.log(error);
   // });
 }
    
const { sent_notifications_id, employee_id } = useParams();

// const { sent_notifications_id, employee_id, gcaptcha } = state;

 
    return (
     
     <React.Fragment>
      <Navigation />
     <div className="content">
     <div className="contact-box">
      <div className="container">
       <div className="contact-inner-box"><p className='flashMsg'>
         {/* <span id="errMsg" className="text-danger">{stateOpen.errMsg}</span> */}
          {/* <span id="errMsg" className="text-danger">{stateOpen.responseData}</span> */}
          </p>
          {mystate.errMsghome_content}
        <div className="contact-left">
        <div dangerouslySetInnerHTML={{__html: mystate.home_content}} />
        
        </div>
        <form id="contact-form" onSubmit={onSubmit} method="POST">
         <div className="contact-right">
          {stateOpen.succMsg ? <div class="alert alert-success" role="alert">{stateOpen.succMsg}</div> : ''}
          {stateOpen.errMsg ? <div class="alert alert-danger" role="alert">{stateOpen.errMsg}</div> : ''}
          
          {mystate.succMsg ? <div class="alert alert-success" role="alert">{mystate.succMsg}</div> : ''}
          {mystate.errMsg ? <div class="alert alert-danger" role="alert">{mystate.errMsg}</div> : ''}

          {state.succMsg ? <div class="alert alert-success" role="alert">{state.succMsg}</div> : ''}
          {state.errMsg ? <div class="alert alert-danger" role="alert">{state.errMsg}</div> : ''}

          <div className="field-row">
           <label>Entrez votre prénom<span className="mand">*</span></label>
           <input type="text" value={state.firstName} name="firstName" onChange={(e) => { setState({ ...state, errMsgFirstName: '' }); onChangeFun(e); }} placeholder="Entrez votre prénom" />
           <span id="errMsgFirstName" className="text-danger">{state.errMsgFirstName}</span>
          </div>

          <div className="field-row">
           <label>Entrez votre nom de famille<span className="mand">*</span></label>
           <input type="text" value={state.lastName} name="lastName" onChange={(e) => { setState({ ...state, errMsgLastName: '' }); onChangeFun(e); }} placeholder="Entrez votre nom de famille" />
           <span id="errMsgLastName" className="text-danger">{state.errMsgLastName}</span>
          </div>

          <div className="field-row">
           <label>Entrer votre Email<span className="mand">*</span></label>
           <input type="email" value={state.email} name="email" onChange={(e) => { setState({ ...state, errMsgEmail: '' }); onChangeFun(e); }} placeholder="Entrer votre Email" />
           <span id="errMsgEmail" className="text-danger">{state.errMsgEmail}</span>
          </div>
          <input type="hidden" value={state.lang='fr'} name="lang" onChange={(e) => { setState({ ...state, errMsgLang: '' }); onChangeFun(e); }}placeholder="Enter your Language" />
          <span id="errMsgEmail" className="text-danger">{state.errMsgLang}</span>
          
          {/* <div className="captcha-row">
           <input type="hidden" value={state.gcaptcha} name="gcaptcha" />
           <ReCAPTCHA ref={e => setCaptcha(e)} onChange={() => setState({ ...state, gcaptcha: true })} onExpired={() => setState({ ...state, gcaptcha: false })} onErrored={() => setState({ ...state, gcaptcha: false })} sitekey="6LdcO84cAAAAANh8vK2jLXflDmUCG-14zAc6Y0ex"
           />
           <span className="text-danger">{state.errMsgGcaptcha}</span>
          </div> */}
          <div className="field-button-row">
           <input type="submit" value="Nous faire parvenir" className="field-button" />
          </div>
         </div>
        </form>
       </div>
      </div>
     </div>

     <div className="subscription-box">
                    <div className="container">

                        <div className="subscription-inner-box">

                            <div className="subscription-left">
                                <h3>Lorem ipsum?</h3>
                                <p>Lorem ipsum dolor sit amet.</p>
                            </div>

                            <div className="subscription-right">
                                <a href="true" className="demo-button">Lorem</a>
                                <a href={"#"} target="_blank" className="sign-button">Ipsum</a>
                            </div>

                        </div>

                    </div>
                </div>
     
    </div>
  </React.Fragment >
    )
}