import { useState } from "react";

export default function ToggleMenuHook () {
    const [isMenuOpen,setIsMenuOpen] = useState(0);

    const toggleClass = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const toggleCancel = () => {
        setIsMenuOpen(!isMenuOpen);
    }
    return { isMenuOpen, toggleCancel,  toggleClass};
}