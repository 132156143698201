import React, { useState, useEffect } from 'react'
import Navigation from './Navigation.js';
import { APIURL } from '../constants/global';
import axios from "axios"; 
import { useParams } from 'react-router-dom';
export default function Home() {

 const [state, setState] = useState(
  {
   lang: '',errMsg:"", succMsg: "", home_content:"", errMsghome_content:'',
   //, gcaptcha: '', errMsgGcaptcha: ''
  });
 useEffect(() => {

  let config = {
   headers: {
     'API-ACCESS-TOKEN': '4942de482cf098fb1e0d5b6ba6dd2f06',
   }
  }


  axios.post(APIURL+"admin/api/getPageContent", {page_name:'home_content'}, config)  
  .then((response) => {
   //access the results here....
   
   if (response.data.status === 1) {
    // alert(JSON.stringify(response.data.responseContent.content));
    setState({    
     errMsg: "", home_content: response.data.responseContent.content, errMsghome_content:'',
     // succMsg: response.data.msg, 
    })
   }
 
   if (response.data.status === 0) {
    
    setState({
     ...state,
     errMsg: response.data.msg, errMsghome_content: response.data.msg, home_content:''
    });
    // setTimeout(() => {
    //  setState({errMsg: "", succMsg: "", resp: "", errMsghome_content: ""
    //  });
    // }, 20000);
   }
  })
 
  .catch((error) => {
   console.log(error);
  });
 }, []);
 
    return (
     
     <React.Fragment>
      <Navigation />
     <div className="content">
     <div className="contact-box">
      <div className="container">
       <div className="contact-inner-box"><p className='flashMsg'>
         {/* <span id="errMsg" className="text-danger">{state.errMsgFormOpened}</span>
          <span id="errMsg" className="text-danger">{state.responseData}</span> */}
          </p>
          {state.errMsghome_content}
        <div className="contact-left">
        <div dangerouslySetInnerHTML={{__html: state.home_content}} />
        
        </div>
         
       </div>
      </div>
     </div>

     <div className="subscription-box">
                    <div className="container">

                        <div className="subscription-inner-box">

                            <div className="subscription-left">
                                <h3>Lorem ipsum?</h3>
                                <p>Lorem ipsum dolor sit amet.</p>
                            </div>

                            <div className="subscription-right">
                                <a href="true" className="demo-button">Lorem</a>
                                <a href={"#"} target="_blank" className="sign-button">Ipsum</a>
                            </div>

                        </div>

                    </div>
                </div>
     
    </div>
  </React.Fragment >
    )
}
