import React from 'react'
import { Link } from 'react-router-dom';
import {
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
    EmailIcon
} from "react-share";
export default function Footer() {
    return (
        <footer></footer>
    )
}
