import React, { useState, useEffect } from 'react'
import Navigation from './Navigation.js';
import { APIURL } from '../constants/global'
import axios from "axios";
 
export default function TermsCondition() {
 const [state, setState] = useState(
  {
   succMsg: "", content: "", errMsgContent:'', errMsg: "",
   //, gcaptcha: '', errMsgGcaptcha: ''
  });
 useEffect(() => {

  let config = {
   headers: {
     'API-ACCESS-TOKEN': '4942de482cf098fb1e0d5b6ba6dd2f06',
   }
  }


  axios.post(APIURL+"admin/api/getPageContent", {page_name:'terms_conditions__content'}, config)  
  .then((response) => {
   //access the results here....
   
   if (response.data.status === 1) {
    // alert(JSON.stringify(response.data.responseContent.content));
    setState({    
     errMsg: "", content: response.data.responseContent.content, errMsgContent:'',
     // succMsg: response.data.msg, 
    })
   }
 
   if (response.data.status === 0) {
    
    setState({
     ...state,
     errMsg: response.data.msg, errMsgContent: response.data.msg, content:''
    });
   }
  })
 
  .catch((error) => {
   console.log(error);
  });
 }, []);


 return (
  <>
   <Navigation />

   <div className="content">
    
   
    
   <div className="shop-box">
     <div className="container">

      <div className="home-about-title">
      <div dangerouslySetInnerHTML={{__html: state.content}} />
      
      </div>

     </div>
    </div>
    {/* <div className="demonstration-box">
         <div className="container">
          <div className="home-about-title">
           <h2><span>Lorem ipsum</span> dolor sit amet</h2>
           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum congue est massa, in porttitor lacus luctus at. Phasellus et vehicula elit, vel lobortis metus. Etiam mauris massa, euismod id blandit ut,</p>
          </div>
          
         </div>
        </div> */}
    
   <div className="subscription-box">
                    <div className="container">

                        <div className="subscription-inner-box">

                            <div className="subscription-left">
                                <h3>Lorem ipsum?</h3>
                                <p>Lorem ipsum dolor sit amet.</p>
                            </div>

                            <div className="subscription-right">
                                <a href="true" className="demo-button">Lorem</a>
                                <a href={"#"} target="_blank" className="sign-button">Ipsum</a>
                            </div>

                        </div>

                    </div>
                </div>
   </div>
  </>
 )
}
