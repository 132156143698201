import './App.css';
import React, { useState, useEffect, useReducer, createContext } from 'react';

import { Home, Footer, AboutUs, Formsubmission, ContactUs, Faq, TermsCondition, PrivacyPolicies} from './components';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
//testing
//export const GlobalContext = createContext({loader:1});
function App(props) {
  const [loader, setIsLoader] = useState(1);
  useEffect(()=>{
    setIsLoader(0);
  });
  return (
    <div className="App">
      {loader?<div id="load"></div>:''}
      <div className="wrapper">
        <Router onUpdate={() => window.scrollTo(0, 0)} >
          <ScrollToTop>
            <Switch>
            {/* <Route exact path='/exam-attempt/:exam_id/:note_id/:token' component={ExamAttempt} /> */}
              <Route path="/" exact component={() => <Home />} />
              <Route path="/about" exact component={() => <AboutUs />} />
              <Route path="/termscondition" exact component={() => <TermsCondition />} />
              <Route path="/formsubmission/:sent_notifications_id/:employee_id" exact component={() => <Formsubmission />} />
              <Route path="/contact" exact component={(loader) => <ContactUs />} />
              <Route path="/faq" exact component={() => <Faq />} />
              <Route path="/privacypolicies" exact component={() => <PrivacyPolicies />} />          
            </Switch>
          </ScrollToTop>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
