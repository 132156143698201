import React, { useState, useEffect } from 'react'
import { APIURL } from '../constants/global'
import ToggleMenuHook from '../ToggleMenu'; 
import MenuOptions from './MenuOptions';
import axios from "axios";
import { useLocation } from "react-router-dom"; 
export default function Navigation() {
    const toggleHook = ToggleMenuHook();
    const sampleLocation = useLocation();
    var pageName;
    if(sampleLocation.pathname == '/about'){
     pageName = 'about_us_content';
    } else if(sampleLocation.pathname == '/termscondition'){
     pageName = 'terms_conditions__content';
    }else{
     pageName = 'home_content';
    }

 const [stateContent, setStateContent] = useState(
  {
   succMsg: "", content: "", errMsgContent:'', errMsg: "",
   //, gcaptcha: '', errMsgGcaptcha: ''
  });
 useEffect(() => {

  let config = {
   headers: {
     'API-ACCESS-TOKEN': '4942de482cf098fb1e0d5b6ba6dd2f06',
   }
  }


  axios.post(APIURL+"admin/api/getPageContent", {page_name: pageName}, config)  
  .then((response) => {
   //access the results here....
   
   if (response.data.status === 1) {
    // alert(JSON.stringify(response.data.responseContent.content));
    setStateContent({    
     errMsg: "", title: response.data.responseContent.title, errMsgContent:'',
     // succMsg: response.data.msg, 
    })
   }
 
   if (response.data.status === 0) {
    
    setStateContent({
     ...stateContent,
     errMsg: response.data.msg, errMsgContent: response.data.msg, content:''
    });
   }
  })
 
  .catch((error) => {
   console.log(error);
  });
 }, []);
    return (
        <div>
            <div className="home-page-header">
                <div className="header">
                    <div className="container">
                        <div className="header-inner-box">
                            <div className="toggle-button-box">
                                <button className="toggle-button" onClick={toggleHook.toggleClass} type="button">
                                    <img src={'/images/toggle-icon.png'} alt=""/>
                                </button> 
                            </div>                          
                            <div className="trial-botton">
                                {/* <a target="_blank" href={MYURL+"about"}>About Us</a><br/>
                                <a target="_blank" href={MYURL+"/termsconditions"}>Terms & Conditions</a> */}
                            </div>
                            <div className={!toggleHook.isMenuOpen?'menu':'menu open'}>
                                <button type="button" onClick={toggleHook.toggleCancel} className="close-menu">X</button>
                                <MenuOptions/>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="home-banner">

                   
         <h1><div dangerouslySetInnerHTML={{__html: stateContent.title}} /></h1>

                </div>

            </div>
        </div>
    )
}
